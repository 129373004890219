<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>传感设备管理</h5>
				<div class="formgroup-inline" v-if="locaInfo.admin != 0">
					<Button label="新增摄像头" icon="pi pi-plus" class="mr-2" @click="openAddCamera('add', '')" />
					<!-- <Button label="导出" class="p-button-success mr-2"	/>
					<Button label="导入" class="p-button-warning mr-2" /> -->

					<Button label="导出" icon="pi pi-download" class="p-button-success mr-2" @click="exportCamera" />
					<FileUpload mode="basic" :url="uploadUrl" name="file"
						:auto="true" @error="onUploadError" @upload="onUploadDone" @before-send="onBeforeSendCameraFile"
						accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						:maxFileSize="1000000" label="导入" chooseLabel="导入" class="mr-2 inline-block" />

					<Button label="下载模板" class="p-button-secondary mr-6" @click="exportTemplate" />
				</div>
				<div class="formgroup-inline mt-3">
					<span class="p-float-label mr-3">
						<Dropdown id="eventEnterprise" :options="enterpriseList" v-model="screen.enterprise" 
							optionLabel="name" optionValue="id" @change="onEnterpriseChange('search')" placeholder="所属企业"
							show-clear="true"></Dropdown>
					</span>
					<span class="p-float-label mr-3">
						<Dropdown id="eventField" :options="fieldList" v-model="screen.field"  placeholder="所属场地"
							optionLabel="site_name" optionValue="id" @change="onFieldChange('search')"
							show-clear="true"></Dropdown>
					</span>
					<span class="p-float-label mr-3">
						<Dropdown id="eventRegion" :options="regionList" v-model="screen.department"  placeholder="所属区域"
							optionLabel="region_name" optionValue="id" show-clear="true"></Dropdown>
					</span>

					<div class="field">
						<label for="cameraNameSearch" class="p-sr-only">摄像头名称</label>
						<InputText id="cameraNameSearch" type="text" v-model="screen.personnelid" placeholder="摄像头名称" />
					</div>
					<Button label="查询" @click="onSearch()"></Button>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card">
				<DataTable :value="sensorList" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
					:rowHover="true" v-model:filters="filters1" filterDisplay="menu" :loading="loading1" v-model:selection="selectedCamera"
					:filters="filters1" responsiveLayout="scroll" selectionMode="single" 
					:lazy="true" :totalRecords="totalCount" @page="onPage"
					:globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']">
					<template #empty>
						No sensors found.
					</template>
					<template #loading>
						Loading sensor data. Please wait.
					</template>

					<Column field="name" header="摄像头名称" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.name }}
						</template>
					</Column>

					<Column field="name" header="IP" style="min-width:10rem">
						<template #body="{ data }">
							{{ data.mac }}
						</template>
					</Column>
					<Column field="name" header="企业" style="min-width:10rem">
						<template #body="{ data }">
							{{ data.enterprise_name }}
						</template>
					</Column>
					<Column field="name" header="场地" style="min-width:10rem">
						<template #body="{ data }">
							{{ data.enterprise_partition_name }}
						</template>
					</Column>
					<Column field="name" header="区域" style="min-width:8rem">
						<template #body="{ data }">
							{{ data.region }}
						</template>
					</Column>
					<Column field="name" header="用户名" style="min-width:8rem">
						<template #body="{ data }">
							{{ data.account }}
						</template>
					</Column>
					<Column field="name" header="密码" style="min-width:8rem">
						<template #body="{ data }">
							{{ data.pwd }}
						</template>
					</Column>
					<Column field="name" header="推送" style="min-width:5rem" v-if="false">
						<template #body="{ data }">
							<InputSwitch v-model="data.is_push" @change="onPushChange(data.id)" />
						</template>
					</Column>
					<Column header="操作" style="min-width:22rem">
						<template #body="{ data }">
							<span class="p-input-icon-left">
								<!-- <Button label="事件" class="p-button-outlined" @click="gotoEventRecords(data)" /> -->
								<!-- <Button label="规则" class="p-button-link" @click="openEditRules(data, index)" /> -->
								<Button label="编辑" class="p-button-success p-button-text mr-1"
									@click="openAddCamera('edit', data)"  v-if="locaInfo.admin != 0" />
								<Button label="删除" class="p-button-danger p-button-text mr-1"
									@click="delCamera(data, $event)"  v-if="locaInfo.admin != 0" />
							</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

	<Dialog :header="lodingtitle" v-model:visible="displayAddCamera" :style="{ width: '50vw' }" :modal="true">
		<div class="field p-fluid">
			<label for="cameraEnterprise">摄像头位置</label>
			<div class="grid">
				<span class="p-float-label mr-3 mt-2 ml-2">
					<Dropdown id="cameraEnterprise" :options="enterpriseList" v-model="lodingarr.enterprise"
						dataKey="id" optionLabel="name" optionValue="id" @change="onEnterpriseChange()" placeholder="所属企业"
						:show-clear="true"></Dropdown>
				</span>
				<span class="p-float-label mr-3 mt-2 ml-2">
					<Dropdown id="cameraField" :options="fieldList" v-model="lodingarr.field" dataKey="id" placeholder="所属场地"
						optionLabel="site_name" optionValue="id" @change="onFieldChange()" :show-clear="true">
					</Dropdown>
				</span>
				<span class="p-float-label mr-3 mt-2 ml-2">
					<Dropdown id="cameraRegion" :options="regionList" v-model="lodingarr.region" dataKey="id" placeholder="所属区域"
						optionLabel="region_name" optionValue="id" @change="onRegionChange()" :show-clear="true">
					</Dropdown>
				</span>
			</div>
		</div>
		<div class="field p-fluid">
			<label for="cameraName">摄像头名称</label>
			<InputText id="cameraName" type="text" class="p-error" v-model="lodingarr.camera"
				aria-describedby="cameraName-help" />
			<small id="cameraName-help" class="p-error" v-show="addCameraNameError">请输入正确的姓名，由中文或者英文组成</small>
		</div>
		<div class="field p-fluid">
			<label for="videoUrl">视频流地址</label>
			<InputText id="videoUrl" type="text" class="p-error" v-model="lodingarr.video"
				aria-describedby="videoUrl-help" />
			<small id="videoUrl-help" class="p-error" v-show="addVideoUrlError">请输入正确的视频流地址</small>
		</div>
		<div class="field p-fluid">
			<label for="cameraIp">摄像头Ip</label>
			<InputText id="cameraIp" type="text" class="p-error" v-model="lodingarr.mac"
				aria-describedby="cameraIp-help" />
			<small id="cameraIp-help" class="p-error" v-show="addCameraIpError">请输入正确的IP地址</small>
		</div>
		<div class="field p-fluid">
			<label for="cameraAccount">账号</label>
			<InputText id="cameraAccount" type="text" class="p-error" v-model="lodingarr.account"
				aria-describedby="cameraAccount-help" />
			<small id="cameraAccount-help" class="p-error" v-show="addCameraAccountError">请输入正确的账号信息</small>
		</div>

		<div class="field p-fluid">
			<label for="cameraPassword">密码</label>
			<InputText id="cameraPassword" type="text" class="p-error" v-model="lodingarr.pwd"
				aria-describedby="cameraPassword-help" />
			<small id="cameraPassword-help" class="p-error" v-show="addCameraPasswordError">请输入正确的密码</small>
		</div>

		<div class="field p-fluid">
			<label for="cameraJudgeRule">审核规则</label>
			<v-md-editor v-model="lodingarr.judge_rule" :height="locaInfo.admin != 0 ? '400px' : ''" :mode="locaInfo.admin == 0 ? 'preview':'editable'"></v-md-editor>
		</div>

		<template #footer >
			<Button label="取消" icon="pi pi-times" @click="displayAddCamera = false" class="p-button-text" />
			<Button label="确定" icon="pi pi-check" @click="doAddCamera" autofocus />
		</template>
	</Dialog>

	<Dialog header="正在导出" v-model:visible="displayLoading" :style="{ width: '30vw' }" :modal="true">
		<p class="m-0">系统正在导出摄像头列表，请稍候</p>
	</Dialog>

	<Dialog :header="locaInfo.admin == 0 ? '查看规则 [':'编辑规则 [' + ruleData.name + ']'" v-model:visible="displayRules" :style="{ width: '50vw' }" :modal="true">
		<!-- <div class="field p-fluid">
			<label for="cameraEnterprise">运行时间</label>
			<div class="grid">
				<span class="p-float-label mr-3 mt-4 ml-2">
					<Calendar id="startTime" v-model="ruleData.start_time" :timeOnly="true" hourFormat="24" />
					<label for="startTime">开始时间</label>
				</span>
				<span class="p-float-label mr-3 mt-4 ml-2">
					<Calendar id="endTime" v-model="ruleData.end_time" :timeOnly="true" hourFormat="24" />
					<label for="endTime">结束时间</label>
				</span>
			</div>
		</div>
		<div class="field p-fluid">
			<label for="cameraName">推送功能</label>
			<div class="grid">
				<span class="p-float-label mr-3 mt-4 ml-2">
					<InputSwitch v-model="ruleData.status" />
				</span>
			</div>
		</div>
		<div class="field p-fluid">
			<label for="cameraName">推送规则</label>
			<div class="grid">
				<div class="col-12 md:col-1">
					<InputText id="cameraAccount" type="text" class="p-error" v-model="ruleData.input"
						aria-describedby="cameraAccount-help" />
				</div>
				<div class="col-12 md:col-2">
					<InlineMessage severity="info">分钟内，预警</InlineMessage>
				</div>
				<div class="col-12 md:col-1">
					<InputText id="cameraAccount" type="text" class="p-error" v-model="ruleData.inputone"
						aria-describedby="cameraAccount-help" />
				</div>
				<div class="col-12 md:col-3">
					<InlineMessage severity="info">次，停止预警推送</InlineMessage>
				</div>
				<div class="col-12 md:col-1">
					<InputText id="cameraAccount" type="text" class="p-error" v-model="ruleData.inputtwo"
						aria-describedby="cameraAccount-help" />
				</div>
				<div class="col-12 md:col-3">
					<InlineMessage severity="info">分钟后，继续推送</InlineMessage>
				</div>
			</div>
		</div> -->
		<div class="field p-fluid">
			<!-- <label for="cameraName">审核规则</label> -->
			<v-md-editor v-model="ruleData.content" :height="locaInfo.admin != 0 ? '400px' : ''" :mode="locaInfo.admin == 0 ? 'preview':'editable'"></v-md-editor>
		</div>
		<template #footer v-if="locaInfo.admin != 0">
			<Button label="取消" icon="pi pi-times" @click="displayRules = false" class="p-button-text" />
			<Button label="确定" icon="pi pi-check" @click="doAddRule" autofocus />
		</template>
	</Dialog>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import CustomerService from "../service/CustomerService";
import ProductService from '../service/ProductService';
import { xlsx_exprot } from "@/utils/xlsx-export";
import { getToken } from '../utils/auth';
export default {
	data() {
		return {
			customer1: null,
			customer2: null,
			customer3: null,
			filters1: null,
			filters2: {},
			loading1: true,
			loading2: true,
			idFrozen: false,
			products: null,
			displayAddCamera: false,
			expandedRows: [],
			
			breadcrumbHome: { icon: 'pi pi-home', label: ' James Butt', to: '#' },
			breadcrumbItems: [
				{ label: '' }
			],

			//---------------------------------------------
			screen: {
				field: null,
				department: null,
				personnelid: null,
				enterprise: null
			},

			totalCount: 0, // 数据总数
			page_index: 1, // 当前页数
			page_size: 10, // 每页条数

			sensorList: [],

			lodingarr: {
				enterprise: "",
				field: "",
				region: "",
				camera: "",
				video: "-",
				// type: '',
				position: "",
				tung: "",
				mac: "",
				account: "",
				pwd: "",
				id: "",
				judge_rule: ""
			},
			lodingtitle: "添加摄像头",

			enterpriseList: null,
			fieldList: null,
			regionList: null,
			positionList: null,
			tungList: null,
			displayLoading: false,
			displayRules: false,
			text: '',

			ruleData: {
				start_time: "",
				state_branch: "",
				end_time: "",
				end_branch: "",
				status: false,
				input: "",
				inputone: "",
				inputtwo: "",
				content: "",
			},
			uploadUrl:'',
			isAdmin: false,
			//---------------------------------------------
			selectedCamera: null,
		}
	},
	customerService: null,
	productService: null,
	created() {
		this.customerService = new CustomerService();
		this.productService = new ProductService();
		this.initFilters1();

		this.screen.enterprise = this.$route.query.enterprise_id;
		this.screen.enterprise_partition_id = this.$route.query.enterprise_partition_id;
		if (this.$route.query.enterprise_id != "") {
			this.getFieldList(this.$route.query.enterprise_id);
		}
		this.locaInfo = JSON.parse(window.localStorage.getItem('userinfo'));
		
		this.getEnterpriseList();
		if (this.locaInfo.type != "ad") {
			this.getFieldList(this.locaInfo.id);
		}
		this.getSensorList();
	},
	mounted() {
		this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
		this.customerService.getCustomersLarge().then(data => {
			this.customer1 = data;
			this.loading1 = false;
			this.customer1.forEach(customer => customer.date = new Date(customer.date));
		});
		this.customerService.getCustomersLarge().then(data => this.customer2 = data);
		this.customerService.getCustomersMedium().then(data => this.customer3 = data);
		this.loading2 = false;
		this.uploadUrl = "http://175.27.161.215:8000/api/admin/camera/import";
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
				'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'representative': { value: null, matchMode: FilterMatchMode.IN },
				'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
				'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
			}
		},

		errorMessage(msg) {
			this.$toast.add({ severity: 'error', summary: '发生错误', detail: msg, life: 2000 });
		},
		goodMessage() {
			this.$toast.add({ severity: 'success', summary: '操作成功', life: 2000 });
		},
		// 获取企业列表
		getEnterpriseList() {
			this.$http("/api/admin/enterprise/index", {}).then((res) => {
				if (res.code == 200) {
					this.enterpriseList = res.data;
				}
			});
		},
		onEnterpriseChange(type) {
			if (type == "search") {
				this.getFieldList(this.screen.enterprise);
			} else {
				this.getFieldList(this.lodingarr.enterprise);
			}
		},

		// 获取场地列表
		getFieldList(enterprise_id) {
			this.$http("/api/admin/partition/index", { enterprise_id }).then(
				(res) => {
					if (res.code == 200) {
						this.fieldList = res.data.data;
					}
				}
			);
		},
		onFieldChange(type) {
			if (type == "search") {
				this.getRegionList(this.screen.field);
			} else {
				this.getRegionList(this.lodingarr.field);
			}
		},

		onBeforeSendCameraFile(e){
			e.xhr.setRequestHeader('admintoken', getToken());
		},

		// 获取区域列表
		getRegionList(enterprise_partition_id) {
			this.$http("/api/admin/region/cdindex", {
				enterprise_partition_id: enterprise_partition_id
			}).then((res) => {
				if (res.code == 200) {
					this.regionList = res.data;
				}
			});
		},
		onRegionChange() {
			// this.getPositionList(this.lodingarr.region);
		},

		// 获取位置列表
		getPositionList(top_id) {
			this.$http("/api/admin/region/xjindex", { top_id }).then((res) => {
				if (res.code == 200) {
					this.positionList = res.data;
				}
			});
		},
		onPositionChange() {
			let name = this.positionList.filter((res) => {
				return res.id == this.lodingarr.position;
			})[0].region_name;
			this.lodingarr.camera = name;
		},

		onPage(event){
			this.page_index = event.page + 1;
			this.getSensorList();
		},

		// 获取栋舍列表
		getTungList(top_id) {
			this.$http("/api/admin/region/xjindex", { top_id }).then((res) => {
				if (res.code == 200) {
					this.tungList = res.data;
				}
			});
		},

		getSensorList() {
			//this.loading = true
			this.$http('/api/admin/camera/index', {
				enterprise_id: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? this.screen.enterprise : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				enterprise_partition_id: this.screen.enterprise_partition_id,
				region_id: this.screen.department,
				name: this.screen.personnelid,
				page: this.page_index,
				page_size: this.page_size
			}).then(res => {
				if (res.code == 200) {
					res.data.data.map(e => {
						e.is_push = e.is_push == 0
					})
					this.sensorList = res.data.data
					this.totalCount = res.data.total
					this.page_size = res.data.per_page

				} else {
					this.$message.error(res.msg)
				}
			})
		},

		// 添加摄像头
		openAddCamera(type, item) {
			//console.log(item);
			if (type == "add") {
				(this.lodingarr = {
					camera: "",
					video: "-",
					type: "",
					mac: "",
					enterprise: null,
					field: null,
					region: null,
					// position: "",
					// tung: "",
					id: "",
					account: "",
					pwd: "",
					judge_rule: "",
				}),
					(this.lodingtitle = "添加摄像头");
			} else {
				this.getFieldList(item.enterprise_id);
				this.getRegionList(item.enterprise_partition_id);
				//this.getPositionList(item.region_id);
				//this.getTungList(item.position_id);

				//console.log(item)

				this.lodingarr = {
					camera: item.name,
					video: item.video_address,
					type: item.type,
					mac: item.mac,
					enterprise: item.enterprise_id,
					field: item.enterprise_partition_id,
					region: item.region_id,
					// position: item.position_id,
					// tung: item.tung_id,
					id: item.id,
					account: item.account,
					pwd: item.pwd,
					judge_rule: item.judge_rule
				};
				this.lodingtitle = "编辑摄像头";
			}
			this.displayAddCamera = true;
		},

		// 弹窗确定
		doAddCamera() {
			this.$http("/api/admin/camera/create", {
				name: this.lodingarr.camera,
				video_address: this.lodingarr.video,
				// type: this.lodingarr.type,
				mac: this.lodingarr.mac,
				enterprise_id: this.lodingarr.enterprise,
				enterprise_partition_id: this.lodingarr.field,
				region_id: this.lodingarr.region,
				position_id: this.lodingarr.position,
				tung_id: this.lodingarr.tung,
				id: this.lodingarr.id,
				account: this.lodingarr.account,
				pwd: this.lodingarr.pwd,
				judge_rule: this.lodingarr.judge_rule
			}).then((res) => {
				if (res.code == 200) {
					this.$toast.add({ severity: 'success', summary: '操作成功' , life: 2000});
					this.getSensorList();
					this.displayAddCamera = false;
					// this.rulelod = true
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg , life: 2000});
				}
			});
		},

		// 摄像头删除
		delCamera(item, event) {
			this.$confirm.require({
				message: '确定要删除这个摄像头吗？',
				target: event.currentTarget,
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http('/api/admin/camera/del', {
						id: item.id
					}).then(res => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '操作成功', life: 2000 });
							this.getSensorList();
						} else {
							this.$toast.add({ severity: 'error', summary: res.msg, life: 2000 });
						}
					})
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},


		// 导出摄像头
		exportCamera() {
			this.displayLoading = true;

			this.$http("/api/admin/camera/index", {
				enterprise_id:
					JSON.parse(window.localStorage.getItem('userinfo')).type == "ad"
						? this.screen.enterprise
						: JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				enterprise_partition_id: this.screen.enterprise_partition_id,
				region_id: this.screen.department,
				name: this.screen.personnelid,
			}).then((res) => {
				const arrs = [];
				for (const item of res.data.data) {
					const arr = [
						item.name,
						item.mac,
						item.video_address,
						item.account,
						item.pwd,
						item.enterprise_partition_name,
						item.region,
						item.is_push == 0 ? "推送" : "不推送",
					];
					arrs.push(arr);
				}
				xlsx_exprot(
					[
						"摄像头名称",
						"摄像头IP",
						"视频流地址",
						"用户名",
						"密码",
						"所属场地",
						"细分区域",
						"是否推送",
					],
					arrs,
					"摄像头管理"
				);

				this.displayLoading = false;
			});
		},

		exportTemplate() {
			this.displayLoading = true;

			const arrs = [
				[
					"摄像头名称",
					"摄像头IP",
					"所属区域",
					"所属位置",
					"企业名称",
					"所属场地",
					"用户名",
					"密码",
					"视频流地址",
					"是否推送（0推送1不推送）",
				],
			];
			xlsx_exprot(
				[
					"name",
					"mac",
					"region_id",
					"position_id",
					"enterprise_id",
					"enterprise_partition_id",
					"account",
					"pwd",
					"video_address",
					"is_push",
				],
				arrs,
				"摄像头倒入模版"
			);

			this.displayLoading = false;
		},

		onUploadError(event) {
			console.log(event)
		},

		onUploadDone(event) {
			var response = JSON.parse(event.xhr.response);
			if (response.msg != "")
				this.errorMessage(response.msg);
			else
				this.$toast.add({ severity: 'success', summary: '导入成功' , life: 2000});
		},
		onSearch() {
			this.getSensorList();
		},

		// 是否推送
		onPushChange(id) {
			this.$http("/api/admin/camera/enable", {
				enterprise_id:
					JSON.parse(window.localStorage.getItem('userinfo')).type == "ad"
						? ""
						: JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				id,
			}).then((res) => {
				if (res.code == 200) {
					//this.$message.success(res.data);
					this.$toast.add({ severity: 'success', summary: res.data , life: 2000});
					this.getSensorList();
				} else {
					this.$toast.add({ severity: 'error', summary: res.data, life: 3000  });
				}
			});
		},

		openEditRules(item, index) {
			let item_name = item.name;
			this.enterprise_camer_id = item.id;
			//console.log(this.selectedCamera)
			console.log(index)

			this.$http("/api/admin/rule/index", {
				enterprise_camer_id: item.id,
			}).then((res) => {
				if (res.code == 200) {
					var _start_time = new Date(res.data.time.split("--")[0]);
					var _end_time = new Date(res.data.time.split("--")[1]);
					this.ruleData = {
						name: item_name,
						start_time: _start_time,
						end_time: _end_time,
						status: res.data.is_push == 0,
						input: res.data.minute,
						inputone: res.data.count,
						inputtwo: res.data.after_minute,
						content: res.data.roles,
					};
				} else {
					this.ruleData = {
						name: item_name,
						start_time: "",
						start_branch: "",
						end_time: "",
						end_branch: "",
						status: "",
						input: "",
						inputone: "",
						inputtwo: "",
						content: "",
					};
				}

				this.displayRules = true;
			});
		},
		gotoEventRecords(data) {
			this.$router.push({
				name: "eventrecords",
				query: { enterprise_camer_id: data.id },
			});
		},
		doAddRule() {
			this.$http("/api/admin/rule/create", {
				time: `${this.ruleData.start_time}--${this.ruleData.end_time}`,
				minute: this.ruleData.input,
				count: this.ruleData.inputone,
				after_minute: this.ruleData.inputtwo,
				is_push: this.ruleData.status ? 0 : 1,
				enterprise_camer_id: this.enterprise_camer_id,
				roles: this.ruleData.content,
			}).then((res) => {
				if (res.code == 200) {
					this.$toast.add({ severity: 'success', summary: '操作成功', life: 1000 });
					this.displayRules = false;
				} else {
					this.$toast.add({ severity: 'error', summary: '操作失败', detail: res.msg, life: 3000 });
				}
			});
		},

		onRowSelect(event) {
			this.$toast.add({ severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		onRowUnselect(event) {
			this.$toast.add({ severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		clearFilter1() {
			this.initFilters1();
		},
		expandAll() {
			this.expandedRows = this.products.filter(p => p.id);
		},
		collapseAll() {
			this.expandedRows = null;
		},
		badgeColor(value) {
			return value > 40 ? 'success' : 'warning';
		},
		removeCompany(id) {
			console.print('remove:' + id);
		},
		editCompany(id) {
			console.print('edit ' + id);
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		formatDate(value) {
			return value.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		calculateCustomerTotal(name) {
			let total = 0;
			if (this.customer3) {
				for (let customer of this.customer3) {
					if (customer.representative.name === name) {
						total++;
					}
				}
			}

			return total;
		}
	},
}

</script>